import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, 
   {
    path: '/game',
    name: 'game',
    component: () => import('@/views/game.vue')
   },
   {
    path: '/myCommunity',
    name: 'myCommunity',
    component: () => import('@/views/myCommunity.vue')
   },
   
   {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index.vue')
   },
   {
    path: '/user',
    name: 'user',
    component: () => import('@/views/user.vue')
   },
     
  {     
     path: '/qbdetail',
    name: 'qbdetail',
    component: () => import('@/views/qbdetail.vue')
  },
      
  {     
    path: '/qbdetails',
   name: 'qbdetails',
   component: () => import('@/views/qbdetails.vue')
 },
 {
     
  path: '/detail',
 name: 'detail',
 component: () => import('@/views/detail.vue')
},
{
     
  path: '/details',
 name: 'details',
 component: () => import('@/views/details.vue')
},
{
     
  path: '/news',
 name: 'news',
 component: () => import('@/views/news.vue')
},
{
     
  path: '/nDetail',
 name: 'nDetail',
 component: () => import('@/views/nDetail.vue')
},





   
  

]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    //设置回到顶部
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

export default router
