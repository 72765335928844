import Web3 from 'web3'
import USDT from './bnbUsdt.json'
import DAPP from './dapp.json'
import { Toast } from 'vant';
//正式
// const ADDRESS_DAPP ="0x4abcd53c99971f3FeBC5ca151bc4E47D705E9518"
const ADDRESS_DAPP ="0x0829E67B1F6f477b10c96192F0cd13F184B6b72D"
const ADDRESS_DA ="0xeafa30694C1fFC801b8f484E4912d161ffDcf840"
const ADDRESS_USDT ="0x55d398326f99059fF775485246999027B3197955"
const get_DA="0xB0b369057BfD02406d3cB1fc3dd6D6510d08623a"
const get_Bnb="0x311386984bf5d1b8aaef4fe8b02c9edb318ea60e"
export const ADDRESS_JLHY='0x0837f8f8aEE5311895B2cEe824E18EB7218d3727'
export const ADDRESS_GSHY='0x0829E67B1F6f477b10c96192F0cd13F184B6b72D'

let ethereum = window.ethereum
    let i = setInterval(() => {
    if ((typeof window.ethereum) !== 'undefined') {
        ethereum = window.ethereum      
        handleWatch()  
        clearInterval(i)  
    }
    }, 1000);

export async function set(){
  ethereum = window.ethereum   
        // console.log('hy',window.ContractEth);
}
export async function handleWatch () {
    if (!window.ethereum) {
        console.log('Metamask is not installed!')
        return false
      }
    window.ethereum.on('accountsChanged',  (accounts) => {
      if(accounts[0]){
        // let web3s = new Web3(ethereum);
        // let msg = web3s.utils.keccak256('授权签名');
        // let ht= await web3s.eth.sign(msg, accounts[0])
        // .then(res=>{return res});  
        // if(ht){
      sessionStorage.setItem('userWallet',accounts[0])  
    //   store.commit("userWallet", accounts[0]);  
    }else{
        sessionStorage.removeItem('userWallet')
      }     
    })
  }
export async function connectTron() {
    const eth_requestAccounts = await ethereum.request({ method: "eth_requestAccounts" });
    // store.commit("userWallet", eth_requestAccounts[0]);
    // return ['0x4A17A0572c96b2Aa186a0224193eBa587444be44']
    return eth_requestAccounts
}

//   获取大区列表
  export function getRegions() {
    ethereum?set():""
    let run = async () => {
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
          let result = await unioContract.methods.getRegion().call();
         return result
    };
    return run();
  }
    // 查询轮数
 export function getCount() {
    let run = async () => {
      ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
          let result = await unioContract.methods.getRoundCount().call();
         return result
    };
    return run();
  }
//   获取当前轮用户
  export function getRoundNows() {
    let run = async () => {
      ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
          let result = await unioContract.methods.getRoundNow().call();
         return result
    };
    return run();
  }
//   查询历史轮的用户信息
  export function getRoundHistroys(oid,index) {
    let run = async () => {
      ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
          let result = await unioContract.methods.getRoundHistroy(index).call();
         return result
    };
    return run();
  }
//   获取大区幸运积分
  export function getRegionPoints(oid) {
    let run = async () => {
      ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
          let result = await unioContract.methods.getRegionPoints(oid).call();
         return result
    };
    return run();
  }
  
 // 查询代币是否授权
 export function getProved(address) {
    let run = async () => {
      ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(USDT, ADDRESS_USDT, {
              from:address
          });
          let result = await unioContract.methods.allowance(address,ADDRESS_DAPP).call()/(10**18);
         return result 
    };
    return run();
  }
  export function getProveds(address) {
    let run = async () => {
      ethereum?set():""
          let web3s = new Web3(ethereum);
          let unioContract = await new web3s.eth.Contract(USDT, ADDRESS_DA, {
              from:address
          });
          let result = await unioContract.methods.allowance(address,ADDRESS_DAPP).call()/(10**18);
         return result 
    };
    return run();
  }
  
 // 授权USDT
 export function toApprove(address,num) {
    let run = async () => {
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(USDT, ADDRESS_USDT);  
        let amount = web3s.utils.toWei(num + '','ether');
        let gas = await web3s.eth.getGasPrice();
        console.log(gas);
        let result = await unioContract.methods.approve(ADDRESS_DAPP, amount).send({from:address, gasPrice:gas}).catch(err => {
            console.log(err.message)
         return false
      });
      if(result){
        return result.transactionHash
    }else{
        return false
    }
    };
    return run();
  }
 // 授权DA
 export function toApproves(address,num) {
    let run = async () => {
      ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(USDT, ADDRESS_DA);  
        let amount = web3s.utils.toWei(num + '','ether');
        let gas = await web3s.eth.getGasPrice();
        console.log(gas);
        let result = await unioContract.methods.approve(ADDRESS_DAPP, amount).send({from:address, gasPrice:gas}).catch(err => {
            console.log(err.message)
         return false
      });
      if(result){
        return result.transactionHash
        }else{
            return false
        } 
    };
    return run();
  }
//   获取用户信息
export async function getUser(address) {  
    let run = async () => {
      ethereum?set():""
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
 
    let result = await unioContract.methods.users(address).call(); 
    if(result){
        // console.log(result,'getUser');
        return  result
    }else{
        return 0
    }
  };
  return run();
}
// 参与
export  function toJoinRound(address,oid){
    let run = async () => {
      ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
       let gas =await web3s.eth.getGasPrice()
        let result = await unioContract.methods.joinRound().send({from:address, gasPrice:gas}).catch(err => {
            console.log(err.message)
            return false
        });
        console.log(result);
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();

}
// 碎片转账
export  function toTransferNFTPoints(address,to,num){
    let run = async () => {
      ethereum?set():""
        let web3s = new Web3(ethereum);
        let unioContract = await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);
       let gas =await web3s.eth.getGasPrice()
       let amount = web3s.utils.toWei(num + '', 'ether');
    //    console.log(address,num);
        let result = await unioContract.methods.transferNFTPoints(to,amount).send({from:address, gasPrice:gas}).catch(err => {
            console.log(err.message)
            return false
        });
        if(result){
            return result.transactionHash
        }else{
            return false
        }
  };
  return run();
}
// 获取个人DA
export async function getDA(address) {  
    let run = async () => {
     let web3s = new Web3(ethereum); 

     let unioContract= await new web3s.eth.Contract(USDT, ADDRESS_DA);  
    let result = await unioContract.methods.balanceOf(address).call(); 
    if(result){
        return  web3s.utils.fromWei(result+'', 'ether');
    }else{
        return 0
    }
  };
  return run();
}

// 转账记录
export async function getTransferHistory(address,size) {  
    let run = async () => {
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
    let result = await unioContract.methods.getTransferHistory(address,size).call(); 
    if(result){
        return  result
    }else{
        return []
    }
  };
  return run();
}


//HT转账
export function transferHt(address,num) {
    let run = async () => {
        let web3s = new Web3(ethereum);
        // let msg = web3s.utils.keccak256('授权签名');
        // let ht= await web3s.eth.sign(msg, address)
        // .then(res=>{return res});
    let amount = web3s.utils.toWei(num+'' , 'ether');
        let result=await  web3s.eth.sendTransaction({
           from:address,
           to:get_Bnb,
           value:amount
       }).then(res=>{
           return res.transactionHash
       }).catch(()=>{ })
       
       return result  
  };
  return run();
}



export async function getMyUsdt() {  
    let run = async () => {
     let web3s = new Web3(ethereum); 
     let unioContract= await new web3s.eth.Contract(USDT, ADDRESS_DA);  
    let result = await unioContract.methods.balanceOf(ADDRESS_DAPP).call(); 
    if(result){
        return  web3s.utils.fromWei(result+'', 'ether');
    }else{
        return 0
    }
  };
  return run();
}



export async function getLuck() {  
  let run = async () => {
   let web3s = new Web3(ethereum); 
   let unioContract= await new web3s.eth.Contract(DAPP, ADDRESS_DAPP);  
  let result = await unioContract.methods.getLuckyPool().call(); 
  if(result){
      return  web3s.utils.fromWei(result+'', 'ether');
  }else{
      return 0
  }
};
return run();
}
export async function transferDA(address,num) {  
  let run = async () => {
   let web3s = new Web3(ethereum); 
   let unioContract= await new web3s.eth.Contract(USDT, ADDRESS_DA);  
   let gas =await web3s.eth.getGasPrice()
    let amount = web3s.utils.toWei(num + '', 'ether');
  let result = await unioContract.methods.transfer(get_DA,amount).send({from:address, gasPrice:gas}).catch(err => {
            return false
        });
        if(result){
            return result.transactionHash
        }else{
            return false
        }
};
return run();
}









