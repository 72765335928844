<template>
<div class="">
    <div class="top">
        
        <div class="logo fadeInLeft">   
       <div class="slogo " > 
         <div class="slo">
         <img src="../assets/img/logo.png" alt=""  class="img2" />
         </div>
         </div>       
        </div>
        <div class="flexright">
          <div class="navbtn fadeInRight" @click="ethConnect">
          <span>{{userWallet?userWallet :'Connect' |yingcang}}</span>
        </div>
          <div class="icosn">
       <img src="../assets/img/menu.png" alt="" class="img" @click="menuopen" />      
       </div></div>
      </div>
      <!-- 菜单 -->
    <van-popup
      v-model="clmenuShows"
      position="right"
      :style="{ height: '100%', 
      width:'60%',
      background: 'linear-gradient(0deg, rgb(30 30 30), rgb(0 0 0))'}"
    >
    <div class="sfelx" >
      <div class="mentop">
        <div class="slogs">
           <img src="../assets/img/logo.png" alt="" class="logo" />
        </div>
        <!-- <img src="../assets/img/gbn.png" alt="" class="close" @click="gbsmenu()"/> -->
      </div>
     
         <div class="menulist">
        <div class="menuitem" :class="active=='/'?'act':''" @click="gbsmenu('/')">
          <div class="left">
            <img src="../assets/img/ic8.png" alt="" />
            <div class="txt">{{$t('m.home')}}</div>
          </div>  
          <div class="right">
            <img src="../assets/img/next.png" alt="" />
          </div>           
        </div>
        <div class="menuitem " :class="active=='/user'?'act':''" @click="gbsmenu('/user')">
          <div class="left">
            <img src="../assets/img/ic9.png" alt="" />
            <div class="txt">{{$t('m.qykp')}}</div>
          </div>   
          <div class="right">
            <img src="../assets/img/next.png" alt="" />
          </div>      
        </div>
        <div class="menuitem " :class="active=='/detail'?'act':''" @click="gbsmenu('/detail')">
          <div class="left">
            <img src="../assets/img/mx.png" alt="" />
            <div class="txt">{{$t('m.zcmx')}}</div>
          </div>   
          <div class="right">
            <img src="../assets/img/next.png" alt="" />
          </div>      
        </div>
        <div class="menuitem " :class="active=='/news'?'act':''" @click="gbsmenu('/news')">
          <div class="left">
            <img src="../assets/img/gg.png" alt="" />
            <div class="txt">{{$t('m.gglb')}}</div>
          </div>   
          <div class="right">
            <img src="../assets/img/next.png" alt="" />
          </div>      
        </div>
      </div>
      <div class="foot">
     
    <div class="lang" >
      <van-popover v-model="show" trigger="click" :actions="langs"   @select="onSelect">
  <template #reference>
    <div class="bbox">
          <img :src="lang.icon" alt="" class="limg">
          <div class="ttl" >{{lang.text}}</div>
          <img src="../assets/img/dw.png"  alt="" class="dwimg" >  
        </div> 
  </template>
</van-popover>       
        </div>
      
        <!-- <div class="price">DA{{$t('m.jg')}}  <span class="num">0.00$</span> </div> -->
        <!-- <div class="minline">
        <img src="../assets/img/bm/icon.png" alt="" class="slog" @click="to('https://twitter.com/Beast_kings')">
         <img src="../assets/img/bm/icon2.png" alt="" class="slog" @click="to('https://ss.transgot.cn/beastkinggroup')">
         </div> -->
      </div>
</div> 
    </van-popup>
    <!-- <div class="purp" v-if="show">
            <div class="linex" v-for="(item,i) in langs" :key="i" @click="changer(item)">
              <img :src="item.img" alt="" class="limg">
              <div class="txt">{{item.name}}</div>
            </div>
          </div> -->
</div>
</template>

<script>
import { connectTron} from "@/api/Public";
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  computed: {
    dqyy() {
      if (this.$store.getters.language) {
        return this.$store.getters.language;
      } else {
        return "en-US";
      }
    },
  },
  data(){
    return{
      userWallet:'',
      clmenuShows:false,
      show:false,
      active:"/",
      lang:{},
      langs:[
        {
          icon: require('../assets/img/cny.png'),
          text:"中文",
          value:'zh-CN'
        },
           {
          icon: require('../assets/img/cny.png'),
          text:"繁體",
          value:'zh-TW'
        },
        
        {
          icon: require('../assets/img/en.png'),
          text:"Englist",
          value:'en-US'
        },
        {
          icon: require('../assets/img/ka.png'),
          text:"한국어",
          value:'ko-CN'
        },
        {
          icon: require('../assets/img/ja.png'),
          text:"日本語",
          value:'ja-CN'
        },

      ]
    }
  },
  filters:{
    yingcang(val) {
      if (val) {
        let a = val.replace(/(\w{7})\w+(\w{4})/, "$1...$2");       
        return a;
      } else {
        return "...";
      }
    },
  },
  created(){
    this.ethConnect()
  },
  methods:{
    ethConnect() {
      connectTron().then((info) => {
        if (info[0].indexOf("0x") == 0) {  
          this.userWallet=info[0]  
          this.$store.commit("userWallet", info[0]); 
                  // this.shows=false                  
    // transferHt(info[0]).then(res=>{
    //   if(res){
        // this.pustadd(info[0])
      // }
      // })
        } else {
          this.$toast(this.$i18n.t("m.qsydzdl"));
        }
      });
    },
    onSelect(item) {
      this.lang=item
      let val=item.value
      this.$i18n.locale = val;
      this.$store.commit("language", val);
      this.show=false
    },
    menuopen() {
      this.langs.map(el=>{
      if(el.value==this.$store.getters.language){
        this.lang=el
      }
    })
    this.active=this.$route.path;
      this.clmenuShows = true;
    },
    gbsmenu(e) {
      if (e == this.active) {
        return;
      }
      this.clmenuShows = false;      
      if(e){
      this.handleFiexdToolItem(e)
      }
    },
    handleFiexdToolItem(e){
    // this.$notify(this.$i18n.t("m.wxtszwkf"))
    // return
  this.$router.push({path:e})
},
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";
.top {
    // height: 156px;
    // @include background_color("background_color1");
    
    position: fixed;
    top: 0;
    z-index: 99;
    
background: #000000bf;
      // background: url("../assets/img/tbg.png")  no-repeat;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 15px;
    width: calc(100%);
    max-width: 750px;
    margin: 0 auto;
    color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #735f24;
    .logo {
      display: flex;
      align-items: center;    
      .slogo{
display: flex;
align-items: center;
      .slo{
// background: linear-gradient(
// 128deg
// ,#ffdf2d,#00e2be);
// background: linear-gradient(
// 128deg
// ,#e7efcd,#00e2be);
// box-shadow: 0px 4px 16px 4px #00e2be;
// border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
      }

.ttt{
font-size: 32px;
font-family:$t-f;
font-weight: 600;
color: #FFFFFF;
margin-left: 10px;
}
        .img2{
          width: auto;
          height: 48px;
          margin-left: 10px;
          // border-radius: 6px;
        }
      }
    }
     .title{
      color: #fff;
      font-size: 36px;
      font-family: $t-mf;
    }
    .navbtn {
      display: flex;
      justify-content: center;
      align-items: center;
     min-width: 170px;
     padding: 0 40px;
height: 60px;
border-radius: 100px;
margin-right: 16px;
 border: 1px solid #735f24;
// background: linear-gradient(188.1deg, rgb(175, 57, 239) 18.52%, rgb(139, 61, 206) 71.67%);
// background: url('../assets/img/bm/b1.png') no-repeat;
background-size: 100% 100%;
font-family: $t-mf;

// background: linear-gradient(
// 128deg
// ,#ffdf2d,#00e2be);
// box-shadow: 0px 4px 16px 4px rgba(62,1,162,1);
// border-radius: 30px;
font-size: 28px;
font-weight: 600;
color: #FFFFFF;
  
    }
      .icosn {
        margin-right: 15px;
       width: 42px;
height: 42px;
// border-radius: 16px;
display: flex;
align-items: center;
justify-content: center;
        .img{
          width: 42px;
        }
      }
      .flexright{
        display: flex;
        align-items: center;
            .lang {
        font-size: 22px;
        font-family: $t-mf;
        font-weight: 600;
       color: #ffffff;
       margin-right: 32px;
        line-height: 42px;
        // margin-left: 20px;
      }
      }
  }

  .sfelx{
    display: flex;
    flex-direction: column;
    height: 100%;
    border-left: 1px solid #735f24;
    background:  #000000 url("../assets/img/pbg.png")  no-repeat;
        //  background-size:contain;
         background-position: center top;
  }

  .mentop {
    display: flex;
    align-items: center;
    margin: 44px 30px;
    position: relative;
    
justify-content: center;
    .slogs{
      //  width: 56px;
// height: 56px;
margin: 44px 30px;
// background: linear-gradient(
// 128deg
// ,#e7efcd,#00e2be);
display: flex;
align-items: center;
justify-content: center;
border-radius: 100%;
   .logo {
      height: 128px;
    }
  
    }
    .close{
      position: absolute;
      right: 0;
      top: 0px;
      width: 56px;
      height: 56px;
    }
    .gb {
      font-size: 32px;
font-family: $t-f;
font-weight: 600;
color: #FFFFFF;
line-height: 44px;
margin-left: 12px;
    }
  }
  .menulist {
    margin-top: 30px;
    .menuitem {
      display: flex;
      align-items: center;      
    justify-content: space-between;
      margin: 20px 24px 20px 44px;
      padding: 20px;
      border: 1px solid #dcbd77;
    border-radius: 39px;
      .left {
        margin-right: 12px;
        text-align: left;
        display: flex;
        img {
          height: 44px;
          width: 44px;
        }
        .txt {
          margin-left: 30px;
          font-family: $t-mf;
          // font-weight: 600;
          font-size: 28px;
          color: #FFFFFF;
          opacity: 0.5;
          line-height: 50px;
        }
      }
      .right {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        img{
          width: 26px;
          height: 26px;
        }
      }
    }
    .act{
       .txt {
         opacity: 1 !important;
       }
    }
  }
  .foot{
        display: flex;
    flex-direction: column;
    flex: 1;
    // justify-content: center;
    padding: 40px;
    .lang{
      display: flex;
      align-items: center;      
    justify-content: center;
    border: 1px solid #735f24;
    width: 240px;
    height: 68px;
    border-radius: 58px;
    margin: 10px auto 40px;
    .bbox{
      display: flex;
      align-items: center;      
    justify-content: center;
    }
      .limg{
        height: 40px;
        width: 40px;
        border-radius: 100%;
      }
      .ttl{
        font-size: 24px;
font-family: $t-f;
font-weight: 600;
color: #FFFFFF;
line-height: 34px;
margin-left: 10px;
margin-right: 10px;
      }
      .dwimg{
        width: 32px;
        margin-left: 10px;
      }
    }
    .btn{
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
          height: 68px;
          width: 100%;
          font-size: 24px;
      color: #ffffff;
          background: linear-gradient(188.1deg, rgb(175, 57, 239) 18.52%, rgb(139, 61, 206) 71.67%);
    }
    .price{
      font-size: 24px;
font-family:$t-f;
font-weight: 600;
color: #FFFFFF;
line-height: 34px;
margin: 18px 0;
text-align: left;
.num{
  margin-left: 20px;
  font-family: $t-mf;
}
    }
    .imgline{
      display: flex;
      align-items: center;
    }
    .slog{
      height: 44px;
      width: 44px;
      margin: 20px;
    }
  }
  .purp{
    position: absolute;
    left: 50%;
    border: 1px solid #20202080;
    transform: translateX(-50%);
    top: 122px;
    background: linear-gradient(135.46deg, rgba(6, 6, 6, 0.5) 24.8%, rgba(32, 32, 32, 0.5) 57.07%);
    padding: 15px 20px;
    inset: 0px auto auto 50%;
    transform: translate3d(-50%, 800px, 0px);
    z-index: 9999;
    .linex{
      display: flex;
      align-items: center;
      margin: 16px;
      .limg{
        width: 40px;
        height: 40px;
        border-radius: 100%;
      }      
      .txt{
        margin-left: 20px;
        font-size: 24px;
        color: #FFFFFF;
      }
    }
  }
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
