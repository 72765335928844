export const m = 
{
    lqlj:"受け取り確定",
    sqinfo:"個人アカウントの機能を使用するには、ウォレットライセンスを使用してください",
    sqbtn:"ウォレットライセンス",
    sqload:"ライセンス中...",
    dltt:"あなたのアカウントにログインする",
    dlinfo:"個人アカウントの機能を使用するには、リンクウォレットをクリックしてください",
    dlbtn:"リンクウォレット",
    bdtt:"親をバインド",
    bdinfo:"推薦人の財布の住所を入力してください",
    bdbtn:"バインドの確認",
    bdload:"バインド中...",
    lqsg:"受け取り成功",
    lqsb:"受け取りに失敗しました",
    デイ:"天",
    shi:"時",
    fen:"分",
    miao:"秒",
    fzcg: "コピー",
fzsb: "コピーに失敗しました",
yhdj: "ユーザレベル",
notice: "ARCは世界初の智算統合サービスネットワークに基づいて建設されたWeb 3智算金融プラットフォームであり、世界初の干渉のない自動化運行を実現した金融サービスプラットフォームでもある。",
cyarc: "ARCへの参加",
ljyy: "今すぐ予約",
yysl:"",
xzsl: "選択数",
qr: "確認",
wdldx: "私の流動性",
wdlp:"マイLP値",
wdqydd: "マイ契約オーダー",
jrgtxq: "ARCに参加して、共にDeFiの新しい波を巻き起こす！",
qyc: "孫",
cbdz: "お金を持っている住所",
wdqy: "私の契約",
qysy: "契約収益",
qrlq: "キャンセルの確認",
lq: "取る",
mx:"詳細",
dqjl: "コミュニティ奨励金",
ny: "あなたは",
wcqbqr: "陳清波確認",
zyyyyd: "注意：24時間以内に予約できるのは1件だけです",
dqsl: "ライセンス数",
yycg: "予約成功",
yysb: "予約に失敗しました",
mysj: "データがない",
sqjd: "コミュニティノード",
gsjd: "コンセンサスノード",
dqjx: "コミュニティ選挙",
gsjdzsl: "コンセンサスノードの総数",
gsjdydcsl: "コンセンサスノードが達成された数",
dqgsjdjc: "現在のコンセンサスノード賞プール",
wdjdjc: "マイノードボーナスプール",
wdcjd: "ノード未達成",
ydcjd: "ノード達成",
djzlmm: "ノードの総量は51個で、セルの業績が2 wドルに達した場合は順にノードになる",
jdmx: "ノード詳細",
xh: "シーケンス番号",
dz: "住所",
sqjxjc: "コミュニティ選挙プール",
wdsqjxjc: "私のコミュニティ選挙の賞池",
xqyjmm: "団地の業績は1万5000ドルに達し、上位51位が選挙プールの奨励金を得ることができる",
sqpm: "コミュニティランキング",
pm: "ランキング",
xqyj: "団地業績",
zyj: "総業績",
dq: "コミュニティ",
wdyqlj: "私の招待リンク",
fz: "コピー",
qysqtj: "契約コミュニティ統計",
zjtjhy: "会員を直接推薦する",
xqyjzje: "団地業績総額",
sqyjzje: "コミュニティ業績総額",
wdfxmx: "私の共有詳細",
wx: "無効",
wdyydd: "私の予約注文",
ywcdqydd: "完了した契約オーダー",
dzf: "支払い待ち",
yyz: "予約中",
dyz: "質押待ち",
qyje: "契約金額",
yydjs: "予約カウントダウン",
zydjs: "質押カウントダウン",
zfdjs: "支払カウントダウン",
wcqydd: "契約注文を完了する",
ywc: "完了しました",
ysh: "買い戻した",
zysl: "質押数量",
jydjs: "解押カウントダウン",
shqydd: "契約書の注文を請け負う",
wcqyddxyzf: "予約注文を完了するには支払いが必要",
shcg: "成功を請け出す",
shsb: "失敗を償う",
zfcg: "支払いに成功しました",
zfsb: "支払いに失敗しました",
wdfx: "私の共有",
yxyh: "有効なユーザー",
wxyh: "無効なユーザー",
grtr: "個人投資",
sqyj: "コミュニティ業績",
sfjd: "ノードかどうか",
shi: "はい",
fou: "いいえ",
home:" トップページ ",
wdsq: "マイコミュニティ",
jdjl: "ノードボーナス",
jxjl: "選挙奨励",
tx: "現金化",
jl: "ご褒美",
sl: "数量",
swjryy:"予約に入っていないと友達を共有できません",
wdtjdz:"私のおすすめ住所",
qqdd:"ウォレット・インタラクションのリクエスト",
dkndqb:"財布を開けて取引アクティビティで確認して注文を続行してください",
zzclsw:"ARCはブロックチェーン取引に従事していますので、取引決済をお待ちください",
ddz:"待機中",
dqzdbmz:"現在のアドレスは招待条件を満たしていません！",
bklq:"受領不可！",

dappname:"ダレノボです",
  wdzc:"私の資産です",
  cz:"チャージします",
  czjl:"チャージ記録です",
  tx:"キャッシュバックです",
  txjl:"キャッシュバック記録です",
  hdqy:"活動区域です",
  djkpsp:"当局カードの破片です:",
  zhang:"張",
  dqxhz:"当地区廃棄値です",
  ljjcze:"プールの累計額です",
  qyfhze:"自己資本配当総額です",
  jrqy:"エリアに入ります。",
  czqr:"チャージ確認です",
  srsl:"数量の入力をお願いします。",
  txqr:"キャッシュバック確認です",
  czcg:"チャージ成功です",
  czsb:"チャージ失敗です",
  txzwkf:"キャッシュはまだ開放されていません。",
  txcg:"キャッシュバック成功です",
  txsb:"キャッシュアウト失敗です",
  yxxq:"ゲームの詳細です",
  jryx:"ゲームに入ります",
  ljcy:"直ちに参加します",
  lun:"ホイール",
  dqrs:"現在の人数です：",
  jxz:"進行中です",
  yjs:"終わりました",
  cyyx:"参加ゲームです",
  wdye:"私の残額です",
  cycg:"参加できました",
  xh:"番号です",
  dz:"アドレスです",
  hd:"獲得します",
  bl:"割合です",
  qykp:"権益カードです",
  kpsl:"カードの総量です",
  yxkp:"有効なカードです",
  wxkp:"無効カードです",
  qyjlljff:"権益奨励累積支給",
  zcmx:"資産明細です",
  sr:"収入です",
  zc:"支出します",
  lx:"タイプです",
  yxzj:"ゲームが当たる",
  qyfh:"自己資本配当金です",
  bz:"備考です",
  di:"第です",
  gglb:"公告の一覧です",
  ggxq:"詳細を公告します。",
  bh:"却下します",
  dsh:"審査待ちです",
  qrz:"確認中です",
  stjsz:"エコロジー構築値です",
wdkpsp:"私のカードの破片です",
grdhsl:"個人のNFT交換量です",
srsls:"交換したNFTの数量を入力します。",
dh:"両替する",
dhnft:"NFTを交換します。",
kpmx:"断片の明細です",
nfttips:"ヒント:666枚ごとにNFTを交換できます。",
bzjhd:"ハズレで獲得する"


}